import { useContext } from 'react'

import { DashboardContext } from 'modules/common/contexts/dashboard-context'

import AnimaLogo from '../../assets/img/layout/anima/logo.svg'
import CiaDeTalentosIcon from '../../assets/img/layout/ciadetalentos/logo-icon.svg'
import CiaDeTalentosLogo from '../../assets/img/layout/ciadetalentos/logo.svg'
import FdcIcon from '../../assets/img/layout/fdc/logo-icon.svg'
import FdcLogo from '../../assets/img/layout/fdc/logo.svg'
import InstitutoAnimaIcon from '../../assets/img/layout/institutoanima/logo-icon.svg'
import InstitutoAnimaLogo from '../../assets/img/layout/institutoanima/logo.svg'
import JnjIcon from '../../assets/img/layout/jnj/logo-icon.svg'
import JnjLogo from '../../assets/img/layout/jnj/logo.svg'
import DefaultLogo from '../../assets/img/layout/logo.svg'
import MagaluLogo from '../../assets/img/layout/magalu/logo.svg'
import MgcIcon from '../../assets/img/layout/magalucloud/logo-icon.svg'
import MgcLogo from '../../assets/img/layout/magalucloud/logo.svg'
import SinergiaIcon from '../../assets/img/layout/sinergia/logo-icon.svg'
import SinergiaLogo from '../../assets/img/layout/sinergia/logo.svg'
import TdsLogo from '../../assets/img/layout/tds/logo.svg'
import umuarama2050Logo from '../../assets/img/layout/umuarama2050/logo.svg'
import equatorialLogo from '../../assets/img/layout/equatorial/logo.svg'
import equatorialIcon from '../../assets/img/layout/equatorial/logo-icon.svg'
import rvdigitalLogo from '../../assets/img/layout/rvdigital/logo.svg'
import pcrLogo from '../../assets/img/layout/pcr/logo.svg'
import pcrIcon from '../../assets/img/layout/pcr/logo-icon.svg'
import luminaLogo from '../../assets/img/layout/lumina/logo.svg'
import luminaIcon from '../../assets/img/layout/lumina/logo-icon.svg'
import cgeeLogo from '../../assets/img/layout/cgee/logo.svg'
import cgeeIcon from '../../assets/img/layout/cgee/logo-icon.svg'
import bateriasMouraLogo from '../../assets/img/layout/bateriasmoura/logo.svg'
import bateriasMouraIcon from '../../assets/img/layout/bateriasmoura/logo-icon.svg'
import saebLogo from '../../assets/img/layout/saeb/logo.svg'
import saebIcon from '../../assets/img/layout/saeb/logo-icon.svg'
import sebraepbLogo from '../../assets/img/layout/sebraepb/logo.svg'
import childhoodLogo from '../../assets/img/layout/childhood/logo.svg'
import childhoodIcon from '../../assets/img/layout/childhood/logo-icon.svg'
import fariasbritoLogo from '../../assets/img/layout/fariasbrito/logo.svg'
import fariasbritoIcon from '../../assets/img/layout/fariasbrito/logo-icon.svg'
import lumosacademyLogo from '../../assets/img/layout/lumosacademy/logo.svg'
import lumosacademyIcon from '../../assets/img/layout/lumosacademy/logo-icon.svg'
import mitsloanreviewLogo from '../../assets/img/layout/mitsloanreview/logo.svg'
import mitsloanreviewIcon from '../../assets/img/layout/mitsloanreview/logo-icon.svg'
import ambevLogo from '../../assets/img/layout/ambev/logo.svg'
import fundacaoitauLogo from '../../assets/img/layout/fundacaoitau/logo.svg'
import portomarinhoLogo from '../../assets/img/layout/portomarinho/logo.svg'
import portomarinhoIcon from '../../assets/img/layout/portomarinho/logo-icon.svg'
import amilLogo from '../../assets/img/layout/amil/logo.svg'
import amilIcon from '../../assets/img/layout/amil/logo-icon.svg'
import deepesgLogo from '../../assets/img/layout/deepesg/logo.svg'
import deepesgIcon from '../../assets/img/layout/deepesg/logo-icon.svg'
import hgsLogo from '../../assets/img/layout/hgs/logo.svg'
import googlecloudLogo from '../../assets/img/layout/googlecloud/logo.svg'
import googlecloudIcon from '../../assets/img/layout/googlecloud/logo-icon.svg'

const actualLogo = (tenant: string) => {
	const { shrink } = useContext(DashboardContext)
	const showContent = !shrink

	const logos: { [key: string]: any } = {
		DEFAULT: DefaultLogo,
		SINERGIA: showContent ? SinergiaLogo : SinergiaIcon,
		ANIMA: AnimaLogo,
		INSTITUTOANIMA: showContent ? InstitutoAnimaLogo : InstitutoAnimaIcon,
		TDS: TdsLogo,
		FDC: showContent ? FdcLogo : FdcIcon,
		MAGALU: MagaluLogo,
		MGC: showContent ? MgcLogo : MgcIcon,
		CIADETALENTOS: showContent ? CiaDeTalentosLogo : CiaDeTalentosIcon,
		JNJ: showContent ? JnjLogo : JnjIcon,
		UMUARAMA2050: umuarama2050Logo
,
		EQUATORIAL: showContent ? equatorialLogo : equatorialIcon,
		RVDIGITAL: rvdigitalLogo,
		PCR: showContent ? pcrLogo : pcrIcon,
		LUMINA: showContent ? luminaLogo : luminaIcon,
		CGEE: showContent ? cgeeLogo : cgeeIcon,
		BATERIASMOURA: showContent ? bateriasMouraLogo : bateriasMouraIcon
,
		SAEB: showContent ? saebLogo : saebIcon,
		SEBRAEPB: sebraepbLogo,
		CHILDHOOD: showContent ? childhoodLogo : childhoodIcon,
		FARIASBRITO: showContent ? fariasbritoLogo : fariasbritoIcon,
		LUMOSACADEMY: showContent ? lumosacademyLogo : lumosacademyIcon,
		MITSLOANREVIEW: showContent ? mitsloanreviewLogo : mitsloanreviewIcon,
		AMBEV: ambevLogo,
		FUNDACAOITAU: fundacaoitauLogo,
		PORTOMARINHO: showContent ? portomarinhoLogo : portomarinhoIcon,
		AMIL: showContent ? amilLogo : amilIcon,
		DEEPESG: showContent ? deepesgLogo : deepesgIcon,
		HGS: hgsLogo,
		GOOGLECLOUD: showContent ? googlecloudLogo : googlecloudIcon
	}

	return logos[tenant]
}

export default actualLogo
