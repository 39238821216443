import React, { createContext, useContext, useEffect, useState } from 'react'

import tenantPermissions from 'config/permissions'

import { useTenant } from 'modules/common/contexts/global-tenant-context'
import { getLabMembers } from 'modules/labs/usecases'
import { useMe } from 'modules/user/hooks'

interface UserTypeContextProps {
	children: React.ReactNode
}

interface UserTypeContextType {
	userType: string
	hasPermissionInList: (
		permission: string,
		option: string,
		userType: string
	) => boolean
	hasPermission: (option: string) => boolean
	labId: string
}

const TypeContext = createContext<UserTypeContextType | undefined>(undefined)

export const useTypeUser = () => {
	const context = useContext(TypeContext)
	if (!context) {
		throw new Error('useTypeUser must be used within a UserTypeContextProvider')
	}
	return context
}

const OWNER = 'OWNER'
const MEMBER = 'MEMBER'
const DEFAULT = 'DEFAULT'

const TENANT_LAB_IDS: { [key: string]: string } = {
	SINERGIA: import.meta.env.VITE_SINERGIA_LAB_ID,
	ANIMA: import.meta.env.VITE_ANIMA_LAB_ID,
	INSTITUTOANIMA: import.meta.env.VITE_INSTITUTOANIMA_LAB_ID,
	TDS: import.meta.env.VITE_TDS_LAB_ID,
	FDC: import.meta.env.VITE_FDC_LAB_ID,
	MAGALU: import.meta.env.VITE_MAGALU_LAB_ID,
	MGC: import.meta.env.VITE_MGC_LAB_ID,
	CIADETALENTOS: import.meta.env.VITE_CIADETALENTOS_LAB_ID,
	JNJ: import.meta.env.VITE_JNJ_LAB_ID,
	UMUARAMA2050: import.meta.env.VITE_UMUARAMA2050_LAB_ID,
	EQUATORIAL: import.meta.env.VITE_EQUATORIAL_LAB_ID,
	RVDIGITAL: import.meta.env.VITE_RVDIGITAL_LAB_ID,
	PCR: import.meta.env.VITE_PCR_LAB_ID,
	LUMINA: import.meta.env.VITE_LUMINA_LAB_ID,
	CGEE: import.meta.env.VITE_CGEE_LAB_ID,
	BATERIASMOURA: import.meta.env.VITE_BATERIASMOURA_LAB_ID,
	SAEB: import.meta.env.VITE_SAEB_LAB_ID,
	SEBRAEPB: import.meta.env.VITE_SEBRAEPB_LAB_ID,
	CHILDHOOD: import.meta.env.VITE_CHILDHOOD_LAB_ID,
	FARIASBRITO: import.meta.env.VITE_FARIASBRITO_LAB_ID,
	LUMOSACADEMY: import.meta.env.VITE_LUMOSACADEMY_LAB_ID,
	MITSLOANREVIEW: import.meta.env.VITE_MITSLOANREVIEW_LAB_ID,
	AMBEV: import.meta.env.VITE_AMBEV_LAB_ID,
	FUNDACAOITAU: import.meta.env.VITE_FUNDACAOITAU_LAB_ID,
	PORTOMARINHO: import.meta.env.VITE_PORTOMARINHO_LAB_ID,
	AMIL: import.meta.env.VITE_AMIL_LAB_ID,
	DEEPESG: import.meta.env.VITE_DEEPESG_LAB_ID,
	HGS: import.meta.env.VITE_HGS_LAB_ID,
	GOOGLECLOUD: import.meta.env.VITE_GOOGLECLOUD_LAB_ID
}

const isUserOwner = (userId: string, labId: string): boolean => {
	return userId === labId
}

const isUserMember = (teamIdList: string[], userId: string): boolean => {
	return !!teamIdList.includes(userId)
}

const UserTypeContext: React.FC<UserTypeContextProps> = ({ children }) => {
	const [userType, setUserType] = useState<string | null>(null)
	const userResponse = useMe()
	const { tenant } = useTenant()
	const permissions = tenantPermissions[tenant]
	const labId = TENANT_LAB_IDS[tenant]

	const getUserType = async (): Promise<string> => {
		const { user } = userResponse

		if (!user) return DEFAULT

		const userId = user.id
		if (isUserOwner(userId, labId)) {
			return OWNER
		}

		let response = null

		try {
			response = await getLabMembers(labId)
		} catch (error) {
			return DEFAULT
		}

		const {
			data: { team }
		} = response

		const teamIdList = team.map((member) => member.id)
		if (isUserMember(teamIdList, userId)) {
			return MEMBER
		}

		return DEFAULT
	}

	useEffect(() => {
		const fetchUserType = async () => {
			const permission = await getUserType()
			if (permission) {
				setUserType(permission)
			}
		}

		if (userResponse.user) {
			fetchUserType()
		}
	}, [userResponse.user?.id])

	const hasPermissionInList = (
		permission: string,
		option: string,
		userType: string
	): boolean => {
		if (!permissions.list[permission]) {
			return true
		}

		if (!permissions.list[permission][userType]) {
			return true
		}

		return !permissions.list[permission][userType].includes(option)
	}

	const hasPermission = (option: string): boolean => {
		if (permissions.key_value[option.toLocaleLowerCase()] != null) {
			return permissions.key_value[option] === true
		} else {
			return true
		}
	}

	return (
		<TypeContext.Provider
			value={{
				userType: userType || DEFAULT,
				hasPermissionInList,
				hasPermission,
				labId
			}}
		>
			{children}
		</TypeContext.Provider>
	)
}

export default UserTypeContext
